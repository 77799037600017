@import '../../../styles/vars';

.check_input{
    p{
        color: $gray700;
        font-weight: 500;
        font-size: 14px;
    }
}

.sum_input{
    div{
        position: relative;
        input{
            padding-left: 72px;
        }
        span{
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            color: $gray500;
        }
    }
}

.seperator{
    position: absolute !important;
    top: 0;
    left: 64px;
    height: 46px;
    width: 1px;
    background-color: $gray300;
}