@import "../../styles/vars";

.data_card{
    padding: 24px;
    border: 1px solid $gray200;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    height: 100%;
}

.title{
    h3{
        color: $gray900;
        font-size: 16px;
        font-weight: 500;
    }
}

.body_text{
    h3{
        font-weight: 600;
        font-size: 36px;
        color: $gray900;
    }
    p{
        color : $gray500;
        font-size: 14px;
    }
}

.increased, .decreased{
    span{
        font-size: 1rem;
    }
}
.increased{
    color: $success500;
}
.decreased{
    color: $error500;
}