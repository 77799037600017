@import '../../../styles/vars';

.label{
    color: $gray700;
    font-weight: 700;
    text-transform: uppercase;
}

.inputs{
    input, select{
        color: $gray500;
        width: 100%;
        border: 1px solid $gray300;
        font-weight: 400;
        border-radius: 8px;
        padding: 10px 14px;
    }
    input:focus{
        color: $gray500;
        border: 1px solid $gray300;
    }
}