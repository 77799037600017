@import "./variables";
@import "./stack";

.btn{
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    line-height: 1;
    padding: 10px 16px;
    span{
        font-size: 1.25rem;
    }
}
.btn-primary{
    color: white;
}
.btn-primary:hover{
    color: white;
}
.btn-secondary{
    color: #344054;
    border: 1px solid #D0D5DD;
}
.btn-secondary:hover{
    border: 1px solid #D0D5DD;
}

.btn-danger{
    color: white;
}

.btn-success{
    color: white;
}