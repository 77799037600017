@import '../../styles/vars';

.page_title{
    h1{
        font-size: 30px;
        font-weight: 500;
        color: $gray900;
    }
    h3{
        font-size: 16px;
        font-weight: 400;
        color: $gray500;
    }
}

.table_container{
  border: 1px solid $gray200;
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
}

.table_header{
    h3{
        font-size: 18px;
        font-weight: 500;
        color: $gray900;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        color: $gray500;
    }
}

.table_wrapper{
    article{
        div{
            border-radius: 0px;
            border: none;
            box-shadow: none;
        }
    }
}