@import '../../../styles/vars';

.search_input{
    input{
        padding-left: 32px !important;
    }
    div:first-of-type{
        position: relative;
        span{
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: $gray500;
        }
    }
    .filtered_list{
        background-color: white;
        color: #667085;
        width: 100%;
        border: 1px solid #d0d5dd;
        font-weight: 400;
        border-radius: 8px;
        font-size: 12px;
        overflow: hidden;
        ul{
            padding-left: 0;
            margin-bottom: 0;
            overflow: hidden;
            li{
                list-style-type: none;
                transition: $transition;
                padding: 10px 14px;
                cursor: pointer;
                &:hover{
                    background-color: $theme;
                    color: white;
                }
            }
        }
    }
    .no_items{
        &:hover{
            color: #667085 !important;
            background-color: white !important;
        }
    }
    .show_list{
        display: block;
    }
    .hide_list{
        display: none;
        border: none !important;
    }
}