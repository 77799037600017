@import '../../styles/vars';


.date_filter_wrapper{
    button{
        font-size: 14px;
        font-weight: 500;
        flex-grow: 1;
        flex-basis: 0;
        color: $gray700;
        border: 1px solid $gray300;
        cursor: pointer;
    }
    button:first-of-type{
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    button:last-of-type{
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.active{
    background-color: $gray300;
}