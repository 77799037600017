@import '../../../styles/vars';

.seperator{
    width: 100%;
    height: 3px;
    background-color: #F2F4F7;
}

.draft{
    margin-bottom: 0;
    font-size: 14px;
    border-radius: 16px;
    padding: 2px 10px;
    font-weight: 500;
    color: $gray700;
    background-color: $gray50;
}