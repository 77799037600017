$theme: #4890cb;

$gray: #344054;
$gray50: #F9FAFB;
$gray200: #E4E7EC;
$gray300: #d0d5dd;
$gray500: #667085;
$gray700: #344054;
$gray900: #101828;

$primary50: #F9F5FF;
$primary100 : #F4EBFF;
$primary600 : #7F56D9;
$primary700 : #6941C6;

$success50 : #ECFDF3;
$success500 : #12B76A;
$success600 : #039855;

$error50: #FFFAEB;
$error500 : #F04438;
$error600: #DC6803;

$transition: all 0.3s linear;

$font: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$borderColor: #d0d5dd;

@mixin display-2xl {
  font-size: 44px;
  font-weight: bold;
}
