@import '../../../styles/vars';

.single_table{
  border: 1px solid $gray200;
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 
  0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    article{
        div{
            border-radius: 0px;
            border: none;
            box-shadow: none;
        }
    }
    h3{
        font-size: 18px;
        font-weight: 500;
        color: $gray900;
        span{
            display: block;
            padding: 2px 8px;
            font-size: 12px;
            color: $primary700;
            background-color: $primary50;
            border-radius: 16px;
            font-weight: 500;
        }
    }
}

.header{
    border-bottom: 1px solid $gray200;
}