@import '../vars';

html, 
body {
  font-weight: 400;
}

.body{
  padding: 20px 40px;
  padding-bottom: 40px;
  form, .form{
    padding: 30px 32px;
  }
}

.body-two{
  padding: 0px 30px;
  padding-bottom: 30px;
}

.nav.nav-tabs{
  border-bottom: none;
  position: relative;
  &::after{
    position: absolute;
    content: "";
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 4px;
    background-color: $gray300;
    z-index: -1;
  }
  .nav-link{
    border: none;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
  }
  .nav-link.active{
    border: none;
    border-color: transparent transparent $theme transparent !important;
    border-bottom: 4px solid $theme;
  }
}

table{
  width: 100%;
  thead{
    background-color: $gray50;
    tr{
      border-bottom: 1px solid $gray200;
      th:first-of-type{
        padding: 14px 24px;
      }
      th{
        color: $gray500;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  tbody{
    tr{
      border-bottom: 1px solid $gray200;
      td:first-of-type{
        padding: 26px 0px 26px 24px;
      }
      td:last-of-type{
        padding: 26px 12px 26px 0px;
        img{
          cursor: pointer;
        }
      }
      td{
        color: $gray500;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.pending, .received, .rejected, .sent, .p-received{
  display: inline-block;
  padding: 2px 8px;
  line-height: 18px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  text-transform: capitalize;
}

.pending{
  color: $gray700;
  background-color: #F2F4F7;
}

.rejected{
  color: #B54708;
  background-color: #FFFAEB;
}

.received{
  color: #027A48;
  background-color: #ECFDF3;
}

.sent{
  color: #175CD3;
  background-color: #EFF8FF;
}

.p-received{
  color: #6941C6;
  background-color: #F9F5FF;
}

.icon{
  margin-left: auto;
  margin-right: auto;
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 1.25rem;
}

.desc{  
  // max-width: 270px;
  p{
    font-size: 14px;
    font-weight: 400;
    color: $gray300;
    margin-bottom: 0;
  }
  p:first-of-type{
    color: $gray900;
  }
}

.company{
  p{
    font-weight: 500;
    font-size: 14px;
    color: $gray900;
    margin-bottom: 0;
  }
}

.modal-dialog.modal-dialog-centered{
  width: fit-content;
  .modal-content{
    border-radius: 12px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  }
} 

.modal-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  background-color: $gray50;
  color: $theme;
  border-radius: 50%;
}

textarea{
  border: 1px solid #d0d5dd;
  width: 100%;
  border-radius: 8px;
  padding: 10px 16px;
}

.modal-icon.reject{
  background-color: $error50;
  color: $error600;
}
.modal-icon.success{
  background-color: $success50;
  color: $success600;
}
.confirmation-popup{
  max-width: 400px;
}

.table-icon{
  font-size: 1.25rem;
  cursor: pointer;
}

.recharts-layer.recharts-bar-rectangle{
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.recharts-legend-item{
  font-size: 12px;
}

.label{
  font-size: 16px;
  color: $gray700;
  font-weight: 700;
  text-transform: uppercase;
}

.table-input{
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
}

.add-email{
  color: #4890cb;
  cursor: pointer;
}

.min-width-form{
  min-width: 350px;
}

.tox-tinymce{
  max-height: 250px;
}

.tox-statusbar__branding{
  display: none;
}