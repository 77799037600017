@import "../../../styles/vars";

.date{
    position: relative;
    span{
        position: absolute;
        top: 46.5%;
        left: 12px;
        transform: translateY(-50%);
        color: $gray500;
        z-index: 5;
    }
    input{
        padding-left: 36px;
    }
}