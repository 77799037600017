@import '../../../styles/vars';

.attachment_wrapper{
    border: 1px solid $gray300;
    border-radius: 8px;
    padding: 16px 24px;
    width: 100%;
    color: $gray500;
}

.no_input{
    text-align: center;
    span{
        padding: 8px 10px;
        border-radius: 50%;
        background-color: #F2F4F7;
        display: inline-block;
    }
    p{
        margin-bottom: 4px;
    }
}

.has_input{
    article{
        div{
            li{
                list-style-type: none;
                p{
                    font-size: 14px;
                    span{
                        color: $gray500;
                        border-radius: 0;
                        background-color: transparent;
                    }
                }
                p:first-of-type{
                    color: $gray700;
                    font-weight: 500;
                }
            }
            span{
                background-color: $primary100;
                color: $primary600;
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
        }
        div:last-of-type{
            color: $gray700;
            font-weight: 700;
            font-size: 1.5rem;
        }
    }
}