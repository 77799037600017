@import "../../../styles/vars";

.main_menu {
  display: block;
  position: fixed;
  left: 0;
  top: 80px;
  bottom: 0;
  background: #ffffff;
  width: 254px;
  //box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid $borderColor;

  hr {
    margin: 6px 0;
  }

  h4 {
    color: #344054;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin: 16px 16px 6px;
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4890cb;
    margin: 6px 16px 12px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      a {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        text-decoration: none;
        color: #475467;
        transition: all 0.2s ease;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        img {
          margin-right: 16px;
        }
      }
    }
  }
}

.isActiveMenuLink {
  background: #f9fafb;
}
