@import '../../styles/vars';

.table_wrapper{
  border: 1px solid $gray200;
  border-radius: 8px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
} 

.paginate_btn.active{
    color: $primary600;
    background-color: $primary50;
    cursor: pointer;
    border-radius: 8px;
}

.cta_container{
    width: fit-content;
}

.paginate_btn{
    color: $gray500;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    transition: $transition;
    border: none;
    outline: none;
}

.checkmark{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    background-color: $gray50;
    color: $theme;
    border-radius: 50%;
}