@import "../vars";

// Your variable overrides
$primary: $theme;
$secondary: #fff;

$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;

$font-family-sans-serif: $font;

$btn-border-radius: 8px;
$btn-border-radius-lg: 8px;
$btn-border-radius-sm: 8px;
$btn-font-family: $font;
//$btn-font-size: $input-btn-font-size;
//$btn-line-height: $input-btn-line-height;
$btn-white-space: nowrap !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-padding-y-sm: 8px;
$btn-padding-x-sm: 16px;
//$btn-font-size-sm: $input-btn-font-size-sm;

$input-focus-border-color: transparent;
$btn-hover-border-shade-amount: 100%;

$input-focus-box-shadow: none !important;
$input-btn-focus-box-shadow: none !important;
$btn-focus-box-shadow: none !important;

$btn-padding-y-lg: 24px;
$btn-padding-x-lg: 32px;
//$btn-font-size-lg: $input-btn-font-size-lg;

// $btn-padding-x: 1.5rem;
// $btn-padding-x-sm: 1.2rem;
// $btn-padding-x-lg: 2rem;

// ========== NAV LINKS ===============
$nav-link-color: black;
$nav-link-font-size: 15px;

$headings-color: #415968;

$input-padding-y: $btn-padding-y;
