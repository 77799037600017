@import "../../../styles/vars";

.top_nav {
  height: 80px;
  background: #fafafa;
  border-bottom: 1px solid $borderColor;
  .dropdown-toggle:before {
    display: none;
    visibility: hidden;
  }
}

.user_menu {
  & > a {
    padding: 0 !important;
    & > button {
      background: transparent;
      box-shadow: none;
      padding: 0;
      border: none !important;
    }
  }
  & > a:after {
    display: none;
  }

  & > div {
    background: #ffffff;
    border: none;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
      0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    li {
      background: transparent !important;
      a {
        color: #667085;
        text-decoration: none;
        display: block;
        transition: color 0.2s ease;
        &:hover {
          color: #101828;
        }
      }
    }
  }
}

.user_menu_button {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    text-transform: none;
    text-align: right;
    color: #667085;
    &:first-child {
      font-weight: 500;
      color: #101828;
    }
  }
  img {
    margin-left: 12px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    height: 48px;
    width: 48px;
  }
}
